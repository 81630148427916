import React, {useEffect, useState} from "react";
import "./Chart.css";
import {IChartDataDto} from "./IChartDataDto";

const tournamentApiEndpoint = "https://gotoitpro.ru/api/v1/tournament"

function Chart() {

    const [data, setData] = useState<IChartDataDto>({
        ready: false,
        error: false,
        last_update: "",
        positions: []
    });

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetch(tournamentApiEndpoint);
            const json = await response.json();

            setData(json);
        };

        fetchData().catch(console.error);
    }, []);

    const data0 = {
        "ready": true,
        "positions": [
            {
                "place": 1,
                "member_name": "Зуев Илья Александрович",
                "score": 12
            },
            {
                "place": 2,
                "member_name": "Бабайкин Валерий Иванович",
                "score": 7
            },
            {
                "place": 3,
                "member_name": "Моне Даниил Владимирович",
                "score": 5
            },
            {
                "place": 4,
                "member_name": "Володченков Никита Сергеевич",
                "score": 3
            },
            {
                "place": 5,
                "member_name": "Плюшкин Артём Александрович",
                "score": 1
            },
            {
                "place": 6,
                "member_name": "Дубодалов Иван Андреич",
                "score": 0
            }
        ],
        "last_update": "2024-09-17T07:40:35.5329035+00:00",
        "error": false
    };

    const ready = data.ready && !data.error;

    const lastUpdate = data.ready
        ? new Date(data.last_update).toLocaleString("ru-RU", { timeZone: "Europe/Moscow" })
        : null;

    return (
        <div className="Chart">
            <div className="Chart__Header">
                <div className="Chart__Container">
                    <p id="Chart__LogoHeader"><b>ВОЙТИ</b>В<b>АЙТИ</b></p>
                    <p id="Chart__TitleHeader">Турнирная таблица</p>
                </div>
            </div>
            <div className="Chart__Content">
                <div className="Chart__Container">
                    {
                        ready &&
                        <table className="Chart__List">
                        {
                            data.positions.map((x, index) =>
                                <ChartItem place={x.place} name={x.member_name} score={x.score} />
                            )
                        }
                        </table>
                    }
                </div>
            </div>
            <div className="Chart__Footer">
            <div className="Chart__Container">
                    {
                        !ready &&
                        <span>Данные подготавливаются...</span>
                    }
                    {
                        ready &&
                        <span>Последнее обновление: {lastUpdate}</span>
                    }
                </div>
            </div>
        </div>
    );
}

function ChartItem(props: {place: number, name: string, score: number}) {

    const placeName = (() => {
        switch (props.place) {
            case 1: return "Gold";
            case 2: return "Bronze";
            case 3: return "Silver";
            default: return null
        }
    })();

    const placeStyleName = placeName ? `Chart__Item__Place--${placeName}` : "";

    return (
        <tr>
            <td>
                <div className={`Chart__Item__Place ${placeStyleName}`}>
                    {props.place}
                </div>
            </td>
            <td>
                <div className="Chart__Item__Name">
                    {props.name}
                </div>
            </td>
            <td>
                {
                    (props.score > 0) &&
                    <div className="Chart__Item__Score">
                        <div className="Chart__Item__ScoreText">{props.score}</div>
                        <div className="Chart__Item__Crown"></div>
                    </div>
                }
            </td>
        </tr>
    );
}

export default Chart;
