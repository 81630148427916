import React from "react";
import "./UnderConstruction.css";
import "./pattern.png";

function UnderConstruction() {
    return (
        <div className="UnderConstruction">
            <div className="UnderConstruction__Header"></div>
            <div className="UnderConstruction__Decoration">
                <div className="UnderConstruction__DecorationLine"></div>
                <div className="UnderConstruction__DecorationText">Under construction</div>
                <div className="UnderConstruction__DecorationLine"></div>
            </div>
            <div className="UnderConstruction__Content">
                <p>Мы всё ещё делаем здесь что-то интересное,<br />но у нас уже есть <a href="/chart">турнирная таблица</a>.</p>
            </div>
            <div className="UnderConstruction__Footer"></div>
        </div>
    );
}

export default UnderConstruction;
